<template>
  <div>
    <!-- <span
      v-if="situacaoPublicacaoId === 'APROVADO'"
      class="blue--text font-weight-bold"
    >
      <v-icon color="blue">mdi-thumb-up</v-icon>
      {{ situacaoPublicacao }}
    </span>
    <span
      v-else-if="situacaoPublicacaoId === 'CANCELADO'"
      class="error--text font-weight-bold"
    >
      <v-icon color="red">mdi-thumb-down</v-icon>
      {{ situacaoPublicacao }}
    </span>
    <span
      v-else-if="situacaoPublicacaoId === 'CONCLUIDO'"
      class="green--text font-weight-bold"
    >
      <v-icon color="green">mdi-check-circle</v-icon>
      {{ situacaoPublicacao }}
    </span>
    <span v-else>
      {{ situacaoPublicacao }}
    </span> -->
    <span>
      {{ situacaoPublicacao }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    situacaoPublicacaoId: {
      type: String,
      required: true
    },
    situacaoPublicacao: {
      type: String,
      required: true
    }
  }
};
</script>
