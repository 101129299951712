<template>
  <v-row justify="center">
    <v-dialog
      value="true"
      max-width="400px"
      max-height="350px"
      scrollable
      @input="closeDialog"
    >
      <v-card class="pa-2" color="#F3F4F5">
        <v-row justify="center" class="header pa-0 ma-0 py-2">
          <div class="pt-1">
            <situacao-post
              :situacao-publicacao-id="post.situacaoPublicacaoId"
              :situacao-publicacao="post.situacaoPublicacao"
            />
          </div>
        </v-row>
        <v-card-text>
          <div class="postContain white pa-2 my-1 px-3">
            <v-row justify="center" class="header pa-0 ma-0 py-2">
              <v-col
                cols="8"
                align-self="center"
                class="d-flex align-center ma-0 pa-0"
              >
                <div class="d-inline-block pr-2 text-center">
                  <v-avatar size="50" color="transparent">
                    <v-img contain :src="post.clienteLogo"></v-img>
                  </v-avatar>
                </div>
                <div class="d-inline-block mt-0 text-left">
                  <div class="font-weight-bold size12">
                    {{ post.clienteNome }}
                  </div>
                  <div class="font-weight-normal size11">
                    <span :key="ind" v-for="(ass, ind) in post.assuntos">
                      {{ ass.descricao }}
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col cols="4" class="black--text size10 text-right">
                {{ post.start | filtraDH }}
              </v-col>
            </v-row>
            <div v-if="post.tipoPublicacaoNome == 'Postagem'" class="postMedia">
              <videoPost
                v-if="post.tpMidia == 'VIDEO'"
                :payload="post"
                :width="315"
                :height="315"
                class="mb-2"
              />
              <v-img
                v-else
                :width="315"
                :height="315"
                :src="post.midiaUrl"
                :lazy-src="post.midiaUrl"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <div class="legenda mt-2">
              <div v-html="post.nameHtml"></div>
              <div v-if="hashtags && post.tipoPublicacaoNome == 'Postagem'">
                <span
                  :key="index"
                  v-for="(hashtag, index) in hashtags"
                  class="primary--text"
                >
                  {{ hashtag }}
                </span>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="btnCancelar" text @click="closeDialog">
            <span class="text-capitalize">Fechar</span>
          </v-btn>
          <v-btn color="primary" @click="callback(post)">
            <span class="text-capitalize">Editar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import videoPost from "./videoComp";
import moment from "moment";
import SituacaoPost from "../../components/situacao-post.vue";

export default {
  components: { videoPost, SituacaoPost },
  props: {
    callback: {
      type: Function
    },
    closeDialog: {
      type: Function
    },
    post: {
      type: Object
    }
  },
  filters: {
    filtraDH(val) {
      if (val) {
        return moment(val).fromNow();
      } else {
        return "Inválido";
      }
    }
  },
  data: () => ({
    playerReady: false,
    erroLoadingVimeo: false,
    hashtags: []
  }),
  beforeMount() {
    if (this.post.hashtags) {
      this.hashtags = this.post.hashtags.split(" ");
    }
  },
  methods: {
    onReady() {
      this.playerReady = true;
    },
    onErro() {
      this.playerReady = false;
      this.erroLoadingVimeo = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.postMedia {
  text-align: center;
}
.legenda {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
</style>
