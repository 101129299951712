<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon x-large :class="classProps">
        <v-badge :value="temFiltrosSelecionados()" overlap bordered>
          <v-icon>mdi-filter-outline</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item v-for="filtro in filtrosPros" :key="filtro.descricao">
          <v-list-item-action>
            <v-switch
              v-model="filtro.value"
              color="primary"
              @change="changeFiltro"
            ></v-switch>
          </v-list-item-action>
          <v-list-item-title>{{ filtro.descricao }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: {
    classProps: String,
    filtrosPros: Array,
    callbackFiltro: Function
  },
  data: () => ({
    menu: false
  }),
  methods: {
    temFiltrosSelecionados() {
      if (this.filtrosPros.length == 0) {
        return false;
      }
      return this.filtrosPros.filter(f => f.value).length > 0;
    },
    changeFiltro() {
      if (this.callbackFiltro) {
        this.callbackFiltro();
      }
    }
  }
};
</script>
