<template>
  <v-container pa-5 fluid>
    <template v-if="loading">
      <v-row no-gutters style="height: 90vh; width: 90vw;" class="text-center">
        <v-col align-self="center">
          <v-progress-circular indeterminate color="primary" :size="70" />
        </v-col>
      </v-row>
    </template>

    <h2 class="mb-5">Postagens</h2>
    <v-card>
      <v-card-text>
        <v-sheet tile height="54" class="d-flex mb-2">
          <v-btn-toggle v-model="type">
            <v-btn value="day">
              Hoje
            </v-btn>

            <v-btn value="week">
              Semana
            </v-btn>

            <v-btn value="month">
              Mês
            </v-btn>
          </v-btn-toggle>

          <p
            class="title text-center mt-2"
            style="width: 100%;"
            v-if="$refs.calendar"
          >
            {{ $refs.calendar.title }}
          </p>

          <v-spacer></v-spacer>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                color="primary"
                class="mr-2 ma-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in tiposPublicacao"
                :key="index"
                @click="newPost(item)"
              >
                <v-img
                  v-if="item.descricao == 'Postagem'"
                  src="@/assets/postagem.png"
                  width="20"
                  height="20"
                />
                <v-img
                  v-if="item.descricao == 'Notificação'"
                  src="@/assets/notificacao.png"
                  width="20"
                  height="20"
                />
                <v-list-item-title class="ml-2">{{
                  item.descricao
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn-toggle>
            <v-btn icon @click="$refs.calendar.prev()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon @click="$refs.calendar.next()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-btn-toggle>

          <filter-group :filtrosPros="filtros" :callbackFiltro="filtrarPosts" />

          <v-btn icon class="mt-1">
            <v-icon
              v-if="viewInList"
              title="Visualizar em calendário"
              x-large
              @click="viewInList = !viewInList"
              >mdi-view-week</v-icon
            >
            <v-icon
              v-else
              title="Visualizar em lista"
              x-large
              @click="viewInList = !viewInList"
              >mdi-view-list</v-icon
            >
          </v-btn>
        </v-sheet>
        <v-sheet v-if="viewInList">
          <v-simple-table style="white-space: nowrap;">
            <template v-slot:default>
              <thead>
                <tr class="grey lighten-2">
                  <th
                    v-for="head in headers"
                    :key="head.text"
                    :class="head.class"
                  >
                    {{ head.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in postsFiltrados" :key="item.id">
                  <td class="text-center">
                    <v-img
                      v-if="item.tipoPublicacaoNome == 'Postagem'"
                      :title="item.tipoPublicacaoNome"
                      src="@/assets/postagem.png"
                      width="20"
                      height="20"
                    />
                    <v-img
                      v-if="item.tipoPublicacaoNome == 'Notificação'"
                      :title="item.tipoPublicacaoNome"
                      src="@/assets/notificacao.png"
                      width="20"
                      height="20"
                    />
                  </td>
                  <td class="text-center">
                    {{ item.start | moment("DD/MM/YYYY HH:mm ") }}
                  </td>
                  <td class="text-left text-truncate" style="max-width: 200px;">
                    <span :title="item.name" v-html="item.name"></span>
                  </td>
                  <td>
                    <situacao-post
                      :situacao-publicacao-id="item.situacaoPublicacaoId"
                      :situacao-publicacao="item.situacaoPublicacao"
                    />
                  </td>
                  <td>{{ item.clienteNome }}</td>
                  <td>
                    <v-chip
                      class="mr-1"
                      v-for="tag in item.assuntos"
                      :key="tag.id"
                      :color="tag.color"
                      small
                      label
                    >
                      {{ tag.descricao }}
                    </v-chip>
                  </td>
                  <td class="text-center">
                    <v-icon
                      class="mr-2"
                      title="Visualizar Post"
                      @click="clickEventList(item)"
                      >mdi-eye-outline</v-icon
                    >
                    <v-icon title="Editar Post" @click="editarPost(item)"
                      >mdi-pencil</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :events="postsFiltrados"
            :event-overlap-mode="mode"
            :event-color="getEventColor"
            :event-overlap-threshold="0"
            @change="getEvents"
            @click:event="clickEventCalendar"
            @click:more="viewDay"
            v-show="!viewInList"
            :first-time="firstTime"
          >
          </v-calendar>
        </v-sheet>
      </v-card-text>
    </v-card>

    <dialog-post
      v-if="showDialogPost"
      :callback="salvarPost"
      :close-dialog="closeDialogPost"
      :post="post"
    />

    <dialog-view-post
      v-if="showDialogViewPost"
      :callback="editarPost"
      :close-dialog="closeViewPost"
      :post="postView"
    />

    <dialog-notificacao
      v-if="showDialogNotificacao"
      :callback="salvarPost"
      :close-dialog="closeDialogPost"
      :post="post"
    />
  </v-container>
</template>

<script>
import FilterGroup from "../../components/filterGroup.vue";
import SituacaoPost from "../../components/situacao-post.vue";
import { PostService, TiposService } from "../../services";
import dialogNotificacao from "./dialog-notificacao.vue";
import dialogPost from "./dialog-post.vue";
import dialogViewPost from "./dialog-view-post.vue";
import { Role } from "@/constants";


export default {
  components: {
    dialogPost,
    dialogViewPost,
    FilterGroup,
    SituacaoPost,
    dialogNotificacao
  },
  data: () => ({
    viewInList: false,
    loading: false,
    showDialogPost: false,
    showDialogViewPost: false,
    showDialogNotificacao: false,
    postView: {},
    dataInicio: null,
    dataFim: null,
    firstTime: "8:00",
    filtros: [],
    post: PostService.defaultPost(),
    posts: [],
    postsFiltrados: [],
    type: "month",
    types: ["month", "week", "day"],
    mode: "column",
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: new Date().toISOString().substr(0, 10),
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1"
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party"
    ],
    headers: [
      { text: "", value: "icone", class: "text-center" },
      { text: "Horário", value: "dhInicioPublicacao", class: "text-center" },
      { text: "Legenda", value: "legenda", class: "text-left" },
      { text: "Situação", value: "situacaoPublicacao", class: "text-left" },
      { text: "Cliente", value: "clienteNome", class: "text-left" },
      { text: "Categorias", value: "assuntos", class: "text-left" },
      // { text: "Tipo", value: "tipoPublicacaoId", class: "text-left" },
      { text: "Ações", value: "", class: "text-center" }
    ],
    tiposPublicacao: []
  }),
  beforeMount() {
    this.loading = true;
    this.tiposPublicacao = this.$store.getters["tipos/getTiposPublicacao"];
    if (this.tiposPublicacao && this.tiposPublicacao.length == 0) {
      TiposService.getAllTiposPublicacao()
        .then(response => {
          this.tiposPublicacao = response.data;
          this.inicializaFiltros(this.tiposPublicacao);
          this.$store.commit("tipos/setTiposPublicacao", this.tiposPublicacao);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.inicializaFiltros(this.tiposPublicacao);
      this.loading = false;
    }
  },
  created(){
    if(this.isMobile){
      this.$router.push('/check-in')
    }
    
    if(this.$store.getters["user/getUser"].roleId !== Role.ROOT && this.$store.getters["user/getUser"].roleId !== Role.ADMIN && this.$store.getters["user/getUser"].roleId !== Role.USER){
      this.$router.push('/check-in')
    }

  },
  methods: {
    getEvents({ start, end }) {
      this.dataInicio = start.date;
      this.dataFim = end.date;
      this.getAllPostByPeriodo();
    },
    viewDay({ date }) {
      this.value = date;
      this.type = "day";
    },
    getAllPostByPeriodo() {
      this.loading = true;
      PostService.getAllPostByPeriodo(this.dataInicio, this.dataFim)
        .then(response => {
          this.posts = [];
          this.postsFiltrados = [];
          if (response.data && response.data.length > 0) {
            this.posts = response.data.map(post => {
              return {
                id: post.id,
                situacaoPublicacao: post.situacaoPublicacao,
                situacaoPublicacaoId: post.situacaoPublicacaoId,
                clienteNome: post.clienteNome,
                midiaUrl: post.midiaUrl,
                tpMidia: post.tpMidia,
                assuntos: post.assuntos,
                ...post.legenda && {name: post.legenda.replace(/<[^>]*>?/gm, "")},
                nameHtml: post.legenda,
                start: post.dhInicioPublicacao,
                color:
                  post.assuntos.length > 0 ? post.assuntos[0].color : "grey",
                timed: true,
                category: post.tipoPublicacaoId,
                tipoPublicacaoId: post.tipoPublicacaoId,
                tipoPublicacaoNome: post.tipoPublicacaoNome,
                hashtags: post.hashtags,
                clienteLogo: post.clienteLogo,
                tipoPublicacao: post.tipoPublicacao,
                isVod: post.isVod
              };
            });
            this.filtrarPosts();
          }
        })
        .catch(erro => {
          if (erro && erro.status && erro.status == 401) {
            return;
          }
          this.$root.showErro(erro.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clickEventCalendar(dados) {
      this.postView = dados.event;
      this.showDialogViewPost = true;
    },
    clickEventList(event) {
      this.postView = event;
      this.showDialogViewPost = true;
    },
    getEventColor(event) {
      return event.color;
    },
    newPost(tipo) {
      this.post = PostService.defaultPost();
      this.post.tipoPublicacaoId = tipo.id;
      this.post.hashtags = tipo.hashtags;
      if (tipo.descricao == "Postagem") {
        this.showDialogPost = true;
      } else {
        this.showDialogNotificacao = true;
      }
    },
    salvarPost() {
      //this.closeDialogPost();
      this.getAllPostByPeriodo();
    },
    closeDialogPost() {
      this.post = null;
      this.showDialogPost = false;
      this.showDialogNotificacao = false;
    },
    editarPost(_post) {
      this.closeViewPost();
      this.loading = true;
      PostService.getPostById(_post.id)
        .then(response => {
          this.post = response.data;
          this.post.dhInicioPublicacao = new Date(this.post.dhInicioPublicacao);
          this.post.dhFimPublicacao = new Date(this.post.dhFimPublicacao);
          if (this.post.tipoPublicacaoNome == "Postagem") {
            this.showDialogPost = true;
          } else {
            this.showDialogNotificacao = true;
          }
        })
        .catch(error => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeViewPost() {
      this.showDialogViewPost = false;
      this.postView = null;
    },

    filtrarPosts() {
      var filtrosIds = this.filtros.filter(f => f.value).map(f => f.id);
      this.postsFiltrados = this.posts.filter(p =>
        filtrosIds.includes(p.tipoPublicacaoId)
      );

      if (
        filtrosIds &&
        filtrosIds.length > 0 &&
        filtrosIds.indexOf("HORARIO") != -1
      ) {
        this.firstTime = "8:00";
      } else {
        this.firstTime = "";
      }
    },
    inicializaFiltros(tiposPublicacao) {
      this.filtros = [
        { id: "HORARIO", value: true, descricao: "A partir das 8h" }
      ];
      this.filtros = this.filtros.concat(
        tiposPublicacao.map(tipo => {
          return {
            id: tipo.id,
            descricao: tipo.descricao,
            value: true
          };
        })
      );
    }
  }
};
</script>

<style></style>
