<template>
  <div class="text-center">
    <v-dialog value="true" persistent width="600px">
      <template v-if="loading">
        <v-row no-gutters style="height: 90vh;" class="text-center">
          <v-col align-self="center">
            <v-progress-circular indeterminate color="primary" :size="70" />
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-card>
          <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
            <v-toolbar-title>
              <span class="headline">{{ formTitle }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon large @click="closeDialog">mdi-close</v-icon>
          </v-toolbar>
          <v-row no-gutters class="pa-2">
            <v-col md="12" cols="12">
              <v-card elevation="0">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  @submit.prevent="salvar(null)"
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <span class="subtitle-2">Agendar notificação</span>
                      </v-col>
                      <v-col cols="12">
                        <v-datetime-picker
                          v-model="post.dhInicioPublicacao"
                          label="Data Notificação*"
                          dateFormat="dd/MM/yyyy"
                          datetime="date"
                          clearText="limpar"
                          :disabled="readOnly"
                        >
                          <template v-slot:dateIcon>
                            <v-icon>mdi-calendar-outline</v-icon>
                          </template>
                          <template v-slot:timeIcon>
                            <v-icon>mdi-clock-outline</v-icon>
                          </template>
                        </v-datetime-picker>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <span class="subtitle-2">Legenda</span>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="post.legenda"
                          rows="3"
                          name="legenda"
                          :rules="[rules.required]"
                          :disabled="readOnly"
                          @focus="showEmoji = false"
                          :counter="255"
                          class="counter-left"
                        />
                      </v-col>
                      <v-col align="right" cols="12">
                        <v-emoji-picker
                          :i18n="i18n"
                          @select="selectEmoji"
                          v-show="showEmoji"
                          class="emoji-position"
                        />
                        <v-btn
                          class="emoji-position"
                          icon
                          color="#000000"
                          @click="showEmoji = !showEmoji"
                        >
                          <v-icon color="#ffcc33" large>mdi-emoticon</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <span class="subtitle-2">Município</span>
                        <v-autocomplete
                          v-model="post.cidadeId"
                          :items="cidades"
                          item-text="descricao"
                          item-value="id"
                          clearable
                          :disabled="readOnly"
                          class="col-no-padding"
                          @change="getBairros"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <span class="subtitle-2">Bairro(s)</span>
                        <v-autocomplete
                          v-model="post.selectedBairros"
                          :items="bairros"
                          item-text="descricao"
                          item-value="id"
                          multiple
                          chips
                          deletable-chips
                          small-chips
                          :disabled="readOnly || !hasCidadeSelected"
                          class="col-no-padding"
                          :loading="loadingBairros"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-menu top :offset-y="true">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="!valid"
                          color="primary"
                          :loading="loadingSalvar"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span class="text-capitalize">Outras Opções</span>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <span v-for="(item, i) in otherOptionsItems" :key="i">
                          <v-list-item
                            v-if="item.show"
                            link
                            @click="item.action"
                            color="primary"
                          >
                            <v-list-item-content>
                              <v-list-item-title>{{
                                item.title
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </span>
                      </v-list>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <v-btn color="btnCancelar" text @click="closeDialog">
                      <span class="text-capitalize">Cancelar</span>
                    </v-btn>
                    <v-btn
                      :disabled="!valid || readOnly"
                      color="primary"
                      type="submit"
                      :loading="loadingSalvar"
                    >
                      <span class="text-capitalize">Salvar</span>
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import {
  PostService,
  TiposService,
  CidadeService,
  InstalacaoService
} from "../../services";
import { Role } from "../../constants";

import { VEmojiPicker } from "v-emoji-picker";

export default {
  components: { VEmojiPicker },
  props: {
    callback: {
      type: Function
    },
    closeDialog: {
      type: Function
    },
    post: {
      type: Object,
      default: PostService.defaultPost()
    },
    disabledAprovar: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    i18n: {
      search: "Pesquisar...",
      categories: {
        Activity: "Atividades",
        Flags: "Bandeiras",
        Foods: "Comida",
        Frequently: "Frequentes",
        Objects: "Objetos",
        Nature: "Natureza",
        Peoples: "Pessoas",
        Symbols: "Símbolos",
        Places: "Locais"
      }
    },
    showEmoji: false,
    valid: false,
    editedIndex: -1,
    readOnly: false,
    rules: {
      required: value => !!value || "Campo obrigatório."
    },
    loadingSalvar: false,
    loading: false,
    situacoesPublicacao: [],
    userData: {},
    loadingBairros: false,
    bairros: [],
    instalacao: [],
    maxSizeLegenda: 255
  }),
  computed: {
    formTitle() {
      return this.post.id ? "Alterar notificação" : "Nova notificação";
    },
    hasCidadeSelected() {
      return this.post.cidadeId !== null;
    },
    showMunicipio() {
      return (
        this.instalacao &&
        Object.keys(this.instalacao).length > 0 &&
        (this.instalacao.habilitaMunicipio === null ||
          this.instalacao.habilitaMunicipio === true)
      );
    },
    showBairros() {
      return (
        this.showMunicipio &&
        this.instalacao &&
        Object.keys(this.instalacao).length > 0 &&
        (this.instalacao.habilitaBairro === null ||
          this.instalacao.habilitaBairro === true)
      );
    },
    otherOptionsItems() {
      return [
        {
          title: "Excluir",
          action: this.excluir,
          show: !this.readOnly
        }
      ];
    }
  },
  beforeMount() {
    this.loading = true;

    this.readOnly = ["CONCLUIDO", "PUBLICADO", "CANCELADO"].includes(
      this.post.situacaoPublicacao
    );

    this.userData = this.$store.getters["user/getUser"];
    this.situacoesPublicacao = this.$store.getters[
      "tipos/getSituacoesPublicacao"
    ];
    this.cidades = this.$store.getters["tipos/getCidades"];

    var promisseSituacoes =
      this.situacoesPublicacao && this.situacoesPublicacao.length > 0
        ? null
        : TiposService.getAllSituacoesPublicacao().then(response => {
            this.situacoesPublicacao = response.data;
            this.$store.commit(
              "tipos/setSituacoesPublicacao",
              this.situacoesPublicacao
            );
          });

    var promisseCidades =
      this.cidades && this.cidades.length > 0
        ? null
        : CidadeService.getAllCidadesSC().then(response => {
            this.cidades = response.data;
            this.$store.commit("tipos/setCidades", this.cidades);
          });

    var promisseInstalacao =
      this.instalacao && this.instalacao.lenght > 0
        ? null
        : InstalacaoService.getAll().then(response => {
            this.instalacao = response.data;
          });

    Promise.all([
      promisseSituacoes,
      promisseCidades,
      promisseInstalacao
    ]).finally(() => {
      this.loading = false;
    });

    if (this.post.id && this.post.cidadeId) {
      this.getBairros();
    }
  },
  methods: {
    salvar(novaSituacao) {
      if (this.$refs.form.validate()) {
        if (!this.validaForm()) {
          return;
        }
        this.loadingSalvar = true;

        let oldSituacao = this.post.situacaoPublicacao;

        if (novaSituacao !== null) {
          this.post.situacaoPublicacao = novaSituacao;
        } else if (oldSituacao === "PENDENTE") {
          this.post.situacaoPublicacao = "APROVADO";
        }
        this.post.dhFimPublicacao = this.post.dhInicioPublicacao
        PostService.salvar(this.post)
          .then(response => {
            this.post.id = response.data.id;
            this.$root.showSucesso("Operação realizada com sucesso");
            this.callback();
            this.closeDialog();
          })
          .catch(erro => {
            this.post.situacaoPublicacao = oldSituacao;
            this.$root.showErro(erro.data);
          })
          .finally(() => {
            this.loadingSalvar = false;
          });
      } else {
        this.$root.showErro("Todos os campos são obrigatórios");
      }
    },
    validaForm() {
      if (this.post.legenda.length > this.maxSizeLegenda) {
        this.$root.showErro(
          `O texto da notificação deve ter no máximo ${this.maxSizeLegenda} caracteres`
        );
        return false;
      }

      if (!this.post.dhInicioPublicacao) {
        this.$root.showErro(
          "O campo <span class='font-weight-black'>data/hora início da notificação</span> é obrigatório!"
        );
        return false;
      }

      if (this.post.dhInicioPublicacao < new Date()) {
        this.$root.showErro(
          "A <span class='font-weight-black'>data/hora início da notificação</span> não pode ser menor que a <span class='font-weight-black'>data/hora atual.</span>"
        );
        return false;
      }

      if (this.post.cidadeId && !(this.post.selectedBairros.length > 0)) {
        this.$root.showErro(
          "O campo <span class='font-weight-black'>Bairro(s)</span> é obrigatório!"
        );
        return false;
      }

      return true;
    },
    disabledSituacao() {
      return (
        !this.post.id ||
        ![Role.ADMIN, Role.ROOT].includes(this.userData.roleId) ||
        this.readOnly
      );
    },
    selectEmoji(emoji) {
      this.post.legenda += emoji.data;
    },
    getBairros() {
      this.loadingBairros = true;
      CidadeService.getBairrosByCidade(this.post.cidadeId)
        .then(response => {
          this.bairros = response.data;
        })
        .finally(() => {
          this.loadingBairros = false;
        });
    },
    excluir() {
      this.salvar("EXCLUIDO");
    }
  }
};
</script>

<style scoped>
.emoji-position {
  padding: 0;
  position: relative;
  top: -40px;
}
.emoji-dialog {
  position: relative;
  z-index: 1000;
}
</style>

<style>
.counter-left .v-text-field__details {
  align-self: start;
}
</style>
